import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mx-auto">
      <h2 className="bg-clouds inline-block my-8 p-3">
        Looks like this page doesn't exist.
        <Link to="/" className="ml-15 no-underline text-viewsource">
          Go back.
        </Link>
      </h2>
    </div>
  </Layout>
)

export default NotFoundPage
